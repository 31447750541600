import styled, { css } from "styled-components";
import {
  Rem, Colors, BP, ShadowBlack, 
} from "../../commons/Theme";
import { StyledButton } from "../globalstyle";

export const StyledBusinessFlipCards = styled.div`
  padding-bottom: ${Rem(48)};

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(72)};
  }
`;

export const StyledBusinessFlipCardsFlexRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
`;

export const StyledBusinessFlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
`;

export const StyledBusinessFlipCard = styled.div`
  background-color: transparent;
  width: 100%;
  perspective: 1000px;
  margin: 10px;
  z-index: 1;

  &:hover ${StyledBusinessFlipCardInner} {
    transform: rotateY(180deg);
  }

  min-height: ${Rem(300)};
  @media (${BP.ipad}) {
    min-height: ${Rem(400)};
  }
`;

export const StyledBusinessFlipCardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  border-radius: 6px;
  padding: ${Rem(10)};

  background-color: white;
  ${ShadowBlack};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledBusinessFlipCardBack = styled.div`
  position: relative;
  backface-visibility: hidden;
  transform: translate3d(0,0,0);
  border-radius: 6px;
  padding: ${Rem(10)};

  min-height: ${Rem(300)};
  @media (${BP.ipad}) {
    min-height: ${Rem(400)};
  }

  background-color: white;
  ${ShadowBlack};
  transform: rotateY(180deg);

  background-image: url('/2020/images/business/collina_sfondo_small.png');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;

  ${(props) =>
    props.isBigOnDesktop
    && css`   
      @media (${BP.ipad}) {
        background-image: url('/2020/images/business/collina_sfondo_big.png');
        background-size: contain;
      }
    `}
`;

export const StyledBusinessFlipCardBackBackground = styled.div`
  min-height: ${Rem(300)};
  @media (${BP.ipad}) {
    min-height: ${Rem(400)};
  }

  background-image: url('/2020/images/business/fiore.png');
  background-repeat: no-repeat;
  background-size: ${Rem(80)};
  background-position: right ${Rem(20)} bottom ${Rem(20)};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isBigOnDesktop
    && css`
      @media (${BP.ipad}) {
        flex-direction: row;
        align-items: flex-start;

        background-image: url('/2020/images/business/fiori.png');
        background-position: left ${Rem(20)} bottom ${Rem(20)};
        background-size: ${Rem(192)};
      }
    `}
`;

export const StyledBusinessFlipCardTitle = styled.h2`
  font-weight: 900;
  font-size: ${Rem(40)};
  text-align: center;
  color: ${Colors.yellow};
  padding-bottom: ${Rem(20)};
`;

export const StyledBusinessFlipCardDescription = styled.h2`
  font-weight: 700;
  font-size: ${Rem(16)};
  line-height: ${Rem(22)};
  text-align: center;
  opacity: 0.5;
  padding: ${Rem(10)};

  ${(props) =>
    props.isBigOnDesktop
    && css`
      @media (${BP.ipad}) {
        text-align: left;
      }
    `}
`;

export const StyledBusinessFlipCardIncludedItem = styled.p`
  font-weight: bold;
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding: 0 ${Rem(20)} ${Rem(10)} ${Rem(20)};
  align-self: flex-start;

  ${(props) =>
    props.isBigOnDesktop
    && css`
      @media (${BP.ipad}) {
        padding: 0 0 ${Rem(10)} 0;
      }
    `}

  @media (${BP.ipad}) {
    padding: 0 ${Rem(50)} ${Rem(10)} ${Rem(50)};
  }
`;

export const StyledBusinessFlipCardBackSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${(props) =>
    props.isBigOnDesktop
    && css`
      @media (${BP.ipad}) {
        max-width: ${Rem(350)};

        align-self: flex-start;
        align-items: flex-start;
        padding-top: ${Rem(110)};

        &:first-child {
          align-items: center;
          padding-top: ${Rem(40)};
          padding-right: ${Rem(20)};
        }
      }
    `}
`;

export const StyledBusinessFlipCardsBackground = styled.div`
  position: absolute;
  width: 100%;

  & div {
    width: 100%;
  }

  margin-top: ${Rem(0)};
  @media (${BP.smartphone}) {
    margin-top: ${Rem(-80)};
  }
  @media (${BP.tablet}) {
    margin-top: ${Rem(-200)};
  }
  @media (${BP.ipad}) {
    margin-top: ${Rem(-320)};
  }
  @media (${BP.desktop}) {
    margin-top: ${Rem(-440)};
  }
  @media (${BP.large}) {
    margin-top: ${Rem(-560)};
  }
  @media (${BP.big}) {
    margin-top: ${Rem(-700)};
  }
`;

export const StyledBusinessFlipCardButton = styled(StyledButton)`
  padding: 0;

  a {
    padding: ${Rem(10)} ${Rem(40)};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    z-index: 1;
    position: relative;
  }
`;
